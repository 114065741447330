<template>
  <div class="e404">
    <img src="@/assets/img/404.jpg" alt="" class="e404-img" />
    <h2 class="e404-title">
      УПС... <br />
      Кажется вы заблудились
    </h2>
  </div>
</template>

<script>
export default {
  name: "E404",
};
</script>
